<template>
    <div>
        <appic-page-title-bar></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl py-0 px-3>
            <v-layout row wrap grid-list-xs>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-tabs
                            v-model="active"
                            color="success"
                            background-color="grey lighten-2"
                            slider-color="appic-green"
                    >
                        <v-tab
                                v-for="tab in tabs"
                                :key="tab.key"
                                ripple
                        >
                            {{tab.title}}

                        </v-tab>
                        <v-tab-item
                            v-for="tab in tabs"
                            :key="tab.key"
                        >
                            <v-card flat>
                                <v-card-text pa-0 ma-0>
                                    <v-layout row justify-space-between>
                                        <v-flex xs6 sm6 class="grey--text darken-3">{{ $t('message.inUSD') }}</v-flex>
                                        <v-flex xs12 sm3 lg2>
                                        <div class="text-right">
                                        <v-btn-toggle
                                                color="primary"
                                                dense
                                                multiple
                                        >
                                          <v-menu offset-y>
                                              <template v-slot:activator="{ on: menu }">
                                                <v-tooltip bottom>
                                                  <template v-slot:activator="{ on: tooltip }">
                                                  <v-btn
                                                      v-on="{...tooltip, ...menu}">
                                                  <v-icon small dark v-on>ti ti-export</v-icon>
                                                  </v-btn>
                                              </template>
                                              <span>{{ $t('message.export') }}</span>
                                            </v-tooltip>
                                          </template>
                                              <v-list>
                                                  <v-list-item @click="">
                                                      <v-list-item-title>{{ $t('message.asPdf') }}</v-list-item-title>
                                                  </v-list-item>
                                                  <v-list-item @click="">
                                                      <v-list-item-title>{{ $t('message.asExcel') }}</v-list-item-title>
                                                  </v-list-item>
                                              </v-list>
                                          </v-menu>
                                        </v-btn-toggle>
                                      </div>
                                      </v-flex>
                                    </v-layout>
                                </v-card-text>
                            </v-card>
                            <v-data-table
                                :headers="report.headers"
                                :items="report.items.malaysia"
                                class="elevation-1 appic-table"
                                disable-pagination
                                dense
                                hide-default-footer
                                hide-default-header
                                calculate-widths
                                fixed-header
                            >
                                <template v-slot:header="{ props: { headers } }">
                                    <thead class="v-data-table-header">
                                    <tr>
                                        <th
                                                v-for="(header,index) in headers"
                                                :key="header.value"
                                                :class=" index <= new Date().getMonth() * 2 ? 'header-border lighten-4' : 'header-table lighten-4'"
                                        >
                                            <span style="color: black !important;">{{ header.text }}</span>
                                        </th>
                                    </tr>
                                    </thead>
                                </template>
                                <template v-slot:body="{items}">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.id">
                                            <td class="font-weight-bold">{{ item.lineitem }}</td>
                                            <td :class="0 < new Date().getMonth() ? 'success-column lighten-3 text-right' : 'orange-column lighten-5 text-right'">{{ numberFormat(item.jan_value,'0,0') }}</td>
                                            <td :class="0 < new Date().getMonth() ? 'success lighten-3 text-right vertical-line' : 'deep-orange lighten-5 text-right vertical-line'">{{ numberFormat(item.jan_pct/100,'0%') }}</td>
                                            <td :class="1 < new Date().getMonth() ? 'success-column lighten-3 text-right' : 'orange-column lighten-5 text-right'">{{ numberFormat(item.feb_value,'0,0') }}</td>
                                            <td :class="1 < new Date().getMonth() ? 'success lighten-3 text-right' : 'deep-orange lighten-5 text-right'">{{ numberFormat(item.feb_pct/100,'0%') }}</td>
                                            <td :class="2 < new Date().getMonth() ? 'success-column lighten-3 text-right' : 'orange-column lighten-5 text-right'">{{ numberFormat(item.mar_value,'0,0') }}</td>
                                            <td :class="2 < new Date().getMonth() ? 'success lighten-3 text-right' : 'deep-orange lighten-5 text-right'">{{ numberFormat(item.mar_pct/100,'0%') }}</td>
                                            <td :class="3 < new Date().getMonth() ? 'success-column lighten-3 text-right' : 'orange-column lighten-5 text-right'">{{ numberFormat(item.apr_value,'0,0') }}</td>
                                            <td :class="3 < new Date().getMonth() ? 'success lighten-3 text-right' : 'deep-orange lighten-5 text-right'">{{ numberFormat(item.apr_pct/100,'0%') }}</td>
                                            <td :class="4 < new Date().getMonth() ? 'success-column lighten-3 text-right' : 'orange-column lighten-5 text-right'">{{ numberFormat(item.may_value,'0,0') }}</td>
                                            <td :class="4 < new Date().getMonth() ? 'success lighten-3 text-right' : 'deep-orange lighten-5 text-right'">{{ numberFormat(item.may_pct/100,'0%') }}</td>
                                            <td :class="5 < new Date().getMonth() ? 'success-column lighten-3 text-right' : 'orange-column lighten-5 text-right'">{{ numberFormat(item.jun_value,'0,0') }}</td>
                                            <td :class="5 < new Date().getMonth() ? 'success lighten-3 text-right' : 'deep-orange lighten-5 text-right'">{{ numberFormat(item.jun_pct/100,'0%') }}</td>
                                            <td :class="6 < new Date().getMonth() ? 'success-column lighten-3 text-right' : 'orange-column lighten-5 text-right'">{{ numberFormat(item.jul_value,'0,0') }}</td>
                                            <td :class="6 < new Date().getMonth() ? 'success lighten-3 text-right' : 'deep-orange lighten-5 text-right'">{{ numberFormat(item.jul_pct/100,'0%') }}</td>
                                            <td :class="7 < new Date().getMonth() ? 'success-column lighten-3 text-right' : 'orange-column lighten-5 text-right'">{{ numberFormat(item.aug_value,'0,0') }}</td>
                                            <td :class="7 < new Date().getMonth() ? 'success lighten-3 text-right' : 'deep-orange lighten-5 text-right'">{{ numberFormat(item.aug_pct/100,'0%') }}</td>
                                            <td :class="8 < new Date().getMonth() ? 'success-column lighten-3 text-right' : 'orange-column lighten-5 text-right'">{{ numberFormat(item.sep_value,'0,0') }}</td>
                                            <td :class="8 < new Date().getMonth() ? 'success lighten-3 text-right' : 'deep-orange lighten-5 text-right'">{{ numberFormat(item.sep_pct/100,'0%') }}</td>
                                            <td :class="9 < new Date().getMonth() ? 'success-column lighten-3 text-right' : 'orange-column lighten-5 text-right'">{{ numberFormat(item.oct_value,'0,0') }}</td>
                                            <td :class="9 < new Date().getMonth() ? 'success lighten-3 text-right' : 'deep-orange lighten-5 text-right'">{{ numberFormat(item.oct_pct/100,'0%') }}</td>
                                            <td :class="10 < new Date().getMonth() ? 'success-column lighten-3 text-right' : 'orange-column lighten-5 text-right'">{{ numberFormat(item.nov_value,'0,0') }}</td>
                                            <td :class="10 < new Date().getMonth() ? 'success lighten-3 text-right' : 'deep-orange lighten-5 text-right'">{{ numberFormat(item.nov_pct/100,'0%') }}</td>
                                            <td :class="11 < new Date().getMonth() ? 'success-column lighten-3 text-right' : 'orange-column lighten-5 text-right'">{{ numberFormat(item.dec_value,'0,0') }}</td>
                                            <td :class="11 < new Date().getMonth() ? 'success lighten-3 text-right' : 'deep-orange lighten-5 text-right'">{{ numberFormat(item.dec_pct/100,'0%') }}</td>
                                            <td class="orange-column lighten-5 text-right">{{ item.total_value }}</td>
                                            <td class="deep-orange lighten-5 text-right">{{ item.total_pct }}</td>
                                        </tr>
                                        <template>
                                            <tr>
                                                <td class="success-column lighten-3 font-weight-bold"></td>
                                                <td :class="0 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="0 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="1 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="1 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="2 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="2 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="3 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="3 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="4 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="4 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="5 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="5 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="6 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="6 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="7 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="7 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="8 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="8 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="9 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="9 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="10 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="10 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="11 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="11 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="12 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'"></td>
                                                <td :class="12 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'"></td>
                                            </tr>
                                            <tr>
                                                <td class="success-column lighten-3 font-weight-bold">Net Profit/Loss</td>
                                                <td :class="0 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'">42</td>
                                                <td :class="0 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'">5</td>
                                                <td :class="1 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'">34</td>
                                                <td :class="1 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'">4</td>
                                                <td :class="2 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'">87</td>
                                                <td :class="2 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'">7</td>
                                                <td :class="3 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'">16</td>
                                                <td :class="3 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'">2</td>
                                                <td :class="4 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'">39</td>
                                                <td :class="4 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'">4</td>
                                                <td :class="5 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'">34</td>
                                                <td :class="5 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'">3</td>
                                                <td :class="6 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'">30</td>
                                                <td :class="6 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'">3</td>
                                                <td :class="7 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'">48</td>
                                                <td :class="7 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'">5</td>
                                                <td :class="8 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'">38</td>
                                                <td :class="8 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'">4</td>
                                                <td :class="9 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'">40</td>
                                                <td :class="9 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'">4</td>
                                                <td :class="10 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'">65</td>
                                                <td :class="10 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'">5</td>
                                                <td :class="11 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'">44</td>
                                                <td :class="11 < new Date().getMonth() ? 'success lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'">4</td>
                                                <td :class="12 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'orange-column lighten-5 text-right font-weight-bold'">518</td>
                                                <td :class="12 < new Date().getMonth() ? 'success-column lighten-3 text-right font-weight-bold' : 'deep-orange lighten-5 text-right font-weight-bold'">4</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-tab-item>
                    </v-tabs>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import { api } from "../../../api";
    import {textTruncate, getCurrentAppLayout, numberFormat} from "Helpers/helpers";

    export default {
        data() {
            return {
                loader: true,
                active: null,
                tabs: [
                    {
                        key: "malaysia",
                        title: "Malaysia"
                    },
                    {
                        key: "indonesia",
                        title: "Indonesia",
                    },
                    {
                        key: "thailand",
                        title: "Thailand",
                    },
                    {
                        key: "vietnam",
                        title: "Vietnam",
                    }
                ],
                report: {
                    headers: [
                        {
                            text: "",
                            value: "lineitem",
                            status: "actual"
                        },
                        {
                            text: "Jan",
                            value: "jan_value",
                            status: "actual"
                        },
                        {
                            text: "%",
                            value: "jan_pct",
                            status: "actual"
                        },
                        {
                            text: "Feb",
                            value: "feb_value",
                            status: "actual"
                        },
                        {
                            text: "%",
                            value: "feb_pct",
                            status: "actual"
                        },
                        {
                            text: "Mar",
                            value: "mar_value",
                            status: "actual"
                        },
                        {
                            text: "%",
                            value: "mar_pct",
                            status: "actual"
                        },
                        {
                            text: "Apr",
                            value: "apr_value",
                            status: "actual"
                        },
                        {
                            text: "%",
                            value: "apr_pct",
                            status: "actual"
                        },
                        {
                            text: "May",
                            value: "may_value",
                            status: "actual"
                        },
                        {
                            text: "%",
                            value: "may_pct",
                            status: "actual"
                        },
                        {
                            text: "June",
                            value: "jun_value",
                            status: "actual"
                        },
                        {
                            text: "%",
                            value: "jun_pct",
                            status: "actual"
                        },
                        {
                            text: "July",
                            value: "jul_value",
                            status: "actual"
                        },
                        {
                            text: "%",
                            value: "jul_pct",
                            status: "actual"
                        },
                        {
                            text: "August",
                            value: "aug_value",
                            status: "actual"
                        },
                        {
                            text: "%",
                            value: "aug_pct",
                            status: "actual"
                        },
                        {
                            text: "September",
                            value: "sep_value",
                            status: "forecast"
                        },
                        {
                            text: "%",
                            value: "sep_pct",
                            status: "forecast"
                        },
                        {
                            text: "October",
                            value: "oct_value",
                            status: "forecast"
                        },
                        {
                            text: "%",
                            value: "oct_pct",
                            status: "forecast"
                        },
                        {
                            text: "November",
                            value: "nov_value",
                            status: "forecast"
                        },
                        {
                            text: "%",
                            value: "nov_pct",
                            status: "forecast"
                        },
                        {
                            text: "December",
                            value: "dec_value",
                            status: "forecast"
                        },
                        {
                            text: "%",
                            value: "dec_pct",
                            status: "forecast"
                        },
                        {
                            text: "TOTAL",
                            value: "total_value",
                            status: "forecast"
                        },
                        {
                            text: "%",
                            value: "total_pct",
                            status: "forecast"
                        }
                    ],
                    items: {
                        malaysia: [],
                        indonesia: [],
                        thailand: [],
                        vietnam: [],
                    },
                    footer: {
                        items: [

                        ]
                    }
                },
                onLoad: true,
                onDocumentLoad: true
            }
        },
        mounted(){
            this.loader = false;
            this.getProfitAndLossForecast();
        },
        methods: {
            numberFormat,
            getProfitAndLossForecast() {
                return api
                    .get("appic/getplforecast")
                    .then(response => {
                        this.loader = false;
                        this.report.items.malaysia = response.data.items.malaysia;
                        this.report.items.indonesia = response.data.items.indonesia;
                        this.report.items.thailand = response.data.items.thailand;
                        this.report.items.vietnam = response.data.items.vietnam;
                        // this.report.headers = response.data.headers;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
        name: "ProfitAndLossReport"
    }
</script>

<style lang="scss">

</style>
